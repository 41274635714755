/**
 * Static Queries for Custom Pages.
 * They must be returned as funcs and no String Interpolation
 * 
 */
import { useStaticQuery, graphql } from "gatsby"

export const getHomepageData = () => {
  const data = HomepageStaticQuery();

  const products = data.wpgraphql.posts.nodes.sort((a, b) => {
    return a.acfProduct.sortOrder - b.acfProduct.sortOrder;
  });

  return { 
    products: products,
    site: data.site,
  }
}

const HomepageStaticQuery = () => {
    return useStaticQuery(graphql`
    fragment FeaturedProductImage on WPGraphQL_Post {
      featuredImage {
        sourceUrl
        altText
        imageFile {
          childImageSharp {
            fluid(maxHeight: 400, quality: 90) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    
    query HomePage {
      site {
        siteMetadata {
          title
        }
      }
      wpgraphql {
        posts(where: {categoryName: "featured-product"},  first: 8) {
          nodes {
            databaseId
            title
            content
            slug
            ...FeaturedProductImage
            categories {
              nodes {
                termTaxonomyId
                name
              }
            }
            acfProduct {
              price
              hardnessOptions
              sortOrder
            }
          }
        }
      }
    }  
    `)
}