import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo" 

// Reed-Shop, 4, 5, 6 (5 best)
import hero from "../images/home/Reed-Shop-Music.jpg"
import reedCloseupImage from "../images/home/hero1.jpeg"

import Product from '../components/store/product'
import Testimonial from '../components/review/testimonial'
import BlogList from '../components/blog/BlogList'

import { getHomepageData } from '../graphql/staticQuery'
import { getBlogData } from '../graphql/blogStaticQuery'
import ReedmakerConfig from "../../content/reedmaker.yaml"

const ArrowRight = () => (
  <span>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
    </svg>
  </span>
)

const SectionHeroBanner = () => (
  <section className="relative bg-gray-100 overflow-hidden border-b">
  <div className="max-w-screen-xl mx-auto">
    <div className="relative z-10 pb-8 bg-gray-100 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-gray-100 transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <main className="mt-12 pt-20 mx-auto max-w-screen-xl px-4 sm:mt-20 sm:px-6 md:mt-12 lg:mt-12 lg:px-8 xl:mt-12">
        <div className="sm:text-center lg:text-left">
          <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            ReedMaker Reed Shop
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            I provide quality Oboe, English Horn, Oboe d’Amore, and Baroque Oboe Reeds to the Professional and Student oboist, and now Bassoon Reeds for the Student and Professional.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <Link to="/shop/" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                Shop
              </Link>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
            <Link to="/learn-more/" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-800 bg-indigo-100 hover:text-red-800 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                Learn More
            </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={hero} alt="" />
  </div>
</section>
)

const SectionShop = ( { products = [] } ) => {
  return (
    <section className="border-b md:flex">
      <div className="container mx-auto px-6 py-16" >
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Oboe Reeds from Student to Professional
        </h2>
        <div className="w-full mb-4">	
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            <div className="flex flex-wrap -mx-6">
              {products.map(product => (
                  <Product
                      key={product.databaseId}
                      title={product.title}
                      id={product.databaseId}
                      storeUri={'/shop/'}
                      slug={product.slug}
                      image={product.featuredImage}
                      price={product.acfProduct.price}
                      hardnessOptions={product.acfProduct.hardnessOptions}
                  />
              ))}
              </div>

              <div className="text-center py-12">
                <Link to="/shop/" className="items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-800 bg-indigo-100 hover:text-red-800 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  See more Products...
                </Link>
              </div>
          </div>
        </div>
    </section>
  )
}

const SectionCustomers= () => {
  const { content } = ReedmakerConfig

  return (
  <section className="container mx-auto px-6 py-16 h-auto text-center">
    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center ">
      What Customers are Saying
    </h2>
    <Testimonial
      description={content.home.testimonial.description}
      name={content.home.testimonial.name}
    />
    <div className="text-center py-4">
      <Link to="/testimonials/" className="items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-800 bg-indigo-100 hover:text-red-800 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
        See All Testimonials
      </Link>
    </div>
  </section>
)}

const SectionHandmade = () => (
    <section className="bg-red-800 py-8">
        <div className="px-4 py-20 md:py-4">
          <div className="md:max-w-6xl md:mx-auto">
            <div className="md:flex md:flex-wrap">


            <div id="hero-banner" className="md:w-1/2 relative">
                <div className="px-4 flex flex-wrap justify-center my-6">
                  <img 
                    src={reedCloseupImage} 
                    alt="oboe reed handmade shop" 
                    className="shadow rounded-full max-w-full h-auto align-middle border-none" 
                    style={{height:350, width:350}} 
                  />
                </div>
              </div>

              <div className="md:w-1/2 text-center md:text-left md:pt-16">
                <h2 className="font-bold text-white text-2xl md:text-5xl leading-tight mb-4">
                  Handmade Oboe Reeds
                </h2>
  
                <h3 className="text-2xl mb-8 text-gray-200 items-center">
                  All my Reeds are totally Guaranteed! There is No Risk! Learn more about my reeds
                  I make ALL Oboe Reeds here in this shop!
                  <ul className="mt-6 items-center">
                    <li className="flex items-center mb-2">
                      <ArrowRight /> Handmade
                    </li>
                    <li className="flex items-center mb-2">
                      <ArrowRight /> 100% Guaranteed
                    </li>
                    <li className="flex items-center mb-2">
                      <ArrowRight /> Reed Emergencies
                    </li>
                  </ul>
                </h3>
              </div>

            </div>
          </div>
        </div>
      </section>
)

const SectionBassoonCallout = () => {
  const { content } = ReedmakerConfig
  if (!content.home.bassoon.on)
    return null
    
  return (
  <section className="bg-gray-100">
    <div className="container mx-auto px-6 py-16 h-auto text-center ">
      <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Bassoon Reed Shop
      </h2>
      <p className="w-full my-2 text-lg text-gray-800 font-bold leading-tight text-center  ">
          Handmade Bassoon Reed products
        </p>

      <div className="text-center py-4">
        <Link to="/bassoon-shop/" className="items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-800 bg-indigo-100 hover:text-red-800 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Shop
        </Link>
      </div>
    </div>
  </section>
)}


const SectionReedmakerMessages = () => (
  <section className="bg-red-800 px-6 py-2 h-auto text-center">
    <h2 className="w-full my-2 text-4xl text-white font-bold leading-tight text-center ">
      COVID-19 (Coronavirus) Information
    </h2>

    <div className="text-center py-4">
      <p className="w-full my-2 text-lg text-white font-bold leading-tight text-center  ">
        All of my Reeds are properly Sanitized and DRIED completely before shipment.<br />
        Please Sanitize again upon receiving your reeds.<br />
        I sanitize all my reeds in 70% Isopropyl Alcohol for 1 minute and then rinse in Listerine for 30 seconds.
      </p>
    </div>
  </section>
)

const SectionBlog = ({ blog = [] }) => {
    return (
      <section className="border-t md:flex">
        <div className="container mx-auto px-6 py-16" >
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
              Oboe Reed Articles
          </h2>
          <BlogList list={blog} />
          <div className="text-center py-4">
            <Link to="/blog/" className="items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-800 bg-indigo-100 hover:text-red-800 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
              See All Articles
            </Link>
          </div>
        </div>
      </section>
    )
}

const SectionAlert = () => {
  const { content } = ReedmakerConfig

  if (!content.home.alert.on)
    return null;

  return (
    <section className="border-b md:flex">
      <div className="container mx-auto " >
        <div className="text-center py-4">
          <p className={`w-full my-2 text-lg ${content.home.alert.style} font-bold leading-tight text-center`}>
            {content.home.alert.message}
          </p>
        </div>

      </div>
    </section>
  )
}

const IndexPage = () => {
  const data = getHomepageData()
  const blog = getBlogData()

  return (
    <Layout>
      <SEO 
        title="Oboe Reeds" 
        meta={[
          {
            name: `google-site-verification`,
            content: 'daKTkC8Hpz-ZtO8UL7Lc90L-wbUqEhpkhrDIeMuJ22c',
          }
        ]} 
      />
      <SectionHeroBanner />
      <SectionAlert />
      <SectionShop products={data.products} />
      <SectionBassoonCallout />
      <SectionHandmade />
      <SectionCustomers />
      <SectionBlog blog={blog} />
      <SectionReedmakerMessages />

    </Layout>
  )
}

export default IndexPage
