/**
 * Static Queries for Custom Pages.
 * They must be returned as funcs and no String Interpolation
 * 
 */
import { useStaticQuery, graphql } from "gatsby"

export const getBlogData = () => {
  const blog = HomepageBlogStaticQuery();

  const blogList = blog.wpgraphql.posts.nodes.sort((a, b) => {

    // tie then use databaseid of newest created first
    if (a.acfProduct.sortOrder == b.acfProduct.sortOrder) {
      return  b.databaseId - a.databaseId // largest to smallest
    }
    return a.acfProduct.sortOrder - b.acfProduct.sortOrder; // smallest to largest
  });

  return blogList
}

const HomepageBlogStaticQuery = () => {
  return useStaticQuery(graphql`
  fragment FeaturedProductImage on WPGraphQL_Post {
    featuredImage {
      sourceUrl
      altText
      imageFile {
        childImageSharp {
          fluid(maxHeight: 400, quality: 90) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
  
  query HomePageBlog {
    wpgraphql {
      posts(where: {categoryName: "blog", orderby: {field: DATE, order: DESC}, status: PUBLISH}, first: 3) {
        nodes {
          databaseId
          title
          date
          content
          slug
          ...FeaturedProductImage
          categories {
            nodes {
              termTaxonomyId
              name
            }
          }
          acfProduct {
            sortOrder
          }

        }
      }
    }
  }  
  `)
}