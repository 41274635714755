
import React from "react"

const Testimonial = ({ description, name, center = true }) => {
    const centerStyle = center ? 'center' : 'left'
    return (
        <blockquote className={`wp-block-quote ${centerStyle}`}>
            <p><em>{description}</em></p>
            <cite>{name}</cite>
        </blockquote>
    )
}

export default Testimonial;
